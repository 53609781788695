<template>
  <div class="editor-page page">
    <loader
      v-if="loading"
      :show="item == null"
      :width="40"
      :height="40"
    ></loader>
    <top-bar
      :title="'Editor Menu ' + title"
      :loading="loading"
      :preview="''"
      :action="false"
      :actionIsValid="isValid"
      @lang="
        (l) => {
          lang = l;
        }
      "
    ></top-bar>
    <Tree
      ref="tree"
      :value="treeData"
      :ondragend="ondragend"
      :eachDroppable="eachDroppable"
      :triggerClass="'drag-handle'"
      @drop="update"
    >
      <div slot-scope="{ node, index, path, tree }">
        <div class="main-content">
          <div class="controls">
            <span class="drag-handle"><DragHandle /></span>
            <button
              v-if="node.children.length > 0"
              @click="tree.toggleFold(node, path)"
            >
              <level-switch :open="!node.$folded" />
            </button>
          </div>
          <div class="content">
            <strong>{{ node.id }}</strong> - {{ node.contents[lang].title }}
          </div>
          <div class="actions">
            -
            <button
              @click="
                () => {
                  node.edit = !node.edit;
                  if (node.edit) {
                    $set(node, 'clone', JSON.parse(JSON.stringify(node)));
                  } else {
                    delete node.clone;
                  }
                }
              "
            >
              modifica
            </button>
            <button @click="deleteMenuItem(node)">elimina</button>
          </div>
        </div>
        <div class="edit-panel d-flex align-items-center" v-if="node.edit">
          <form class="form ml-4">
            <div class="form-group">
              <label for="titolo">Titolo</label>
              <input
                v-model="node.clone.contents[lang].title"
                type="text"
                class="form-control"
                id="titolo"
              />
            </div>

            <div class="form-group">
              <label for="link">Link</label>
              <input
                v-model="node.clone.contents[lang].link"
                type="text"
                class="form-control"
                id="link"
              />
            </div>
            <div class="form-group">
              <label for="target">Target</label>
              <select
                v-model="node.clone.target"
                type="text"
                class="form-control"
                id="target"
              >
              <option value="_blank"> blank [Apre il documento collegato in una nuova finestra o scheda] </option>
              <option value="_self"> self [ Apre il documento collegato nella stessa cornice in cui è stato fatto clic (questo è l'impostazione predefinita) ] </option>
              </select>
            </div>
            <button
              type="button"
              @click="
                () => {
                  node.cover = Object.assign(
                    {},
                    JSON.parse(JSON.stringify(node.clone.cover))
                  );
                  node.contents = Object.assign(
                    {},
                    JSON.parse(JSON.stringify(node.clone.contents))
                  );
                  saveNode(node);
                }
              "
              class="btn btn-secondary"
            >
              Salva
            </button>
            <button
              type="button"
              @click="
                () => {
                  delete node.clone;
                  node.edit = false;
                }
              "
              class="btn btn-primary"
            >
              Annulla
            </button>
          </form>
        </div>
      </div>
    </Tree>

    <div class="form form-section">
      <h5>
        Aggiungi menuitem
        <lang-switcher
          class="ls"
          v-model="newCatLang"
          :langs="$available_langs"
          :btnClass="'light'"
        >
        </lang-switcher>
      </h5>
      <div class="form-group">
        <label>Titolo</label>
        <input
          type="text"
          class="input form-control"
          v-model="newCatInfo[newCatLang].title"
        />
      </div>

      <div class="form-group">
        <label>Link</label>
        <input
          type="text"
          class="input form-control"
          v-model="newCatInfo[newCatLang].link"
        />
      </div>

      <div class="form-group">
              <label for="target">Target</label>
              <select
                v-model="newCatInfo.target"
                type="text"
                class="form-control"
                id="target"
              >
              <option value="_blank"> blank [Apre il link in una nuova scheda] </option>
              <option value="_self" selected> self [Apre il link nella stessa scheda ] </option>
              </select>
            </div>

      <button class="btn btn-primary" type="button" @click="addMenuItem()">
        aggiungi
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.he-tree {
  font-size: 1rem;

  .tree-children {
    transition-duration: 0.4s;
    transition-property: transform;
  }
  .tree-node {
    background-color: white;
    border: 1px solid #ccc;
    margin-bottom: 5px;
    padding: 5px;
    cursor: pointer;

    &:hover {
      .actions {
        opacity: 1;
      }
    }
  }

  .actions button,
  .controls button {
    border: none;
    color: #007bff;
    background: transparent;
  }
  .drag-handle {
    cursor: grab;
    padding: 10px;
  }

  .main-content {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }

  .actions {
    margin-left: 10px;
    color: #007bff;
    font-size: 0.7rem;
    opacity: 0;
    transition: 0.4s all;
  }
  .edit-panel {
    margin: 10px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
  }

  img.cover {
    width: 200px;
    height: 200px;
    object-fit: contain;
    background-color: #f1f1f1;
  }
}

.form-section {
  border: 1px solid #ccc;
  margin: 1rem 0;
  padding: 1rem;
  background-color: white;

  .btn.light {
    border: none !important;

    &.btn-primary {
      background-color: transparent;
      border-color: #7a9e9f;
      color: #000;
    }
  }

  .ls {
    display: inline;
    padding: 5px;
  }
}
</style>
<script>
import { DragHandle, LevelSwitch } from "@/components/icons";

import {
  Tree, // Base tree
  Fold,
  Check,
  Draggable, // plugins
  foldAll,
  unfoldAll,
  cloneTreeData,
  walkTreeData,
  getPureTreeData, // utils
} from "he-tree-vue";
import "he-tree-vue/dist/he-tree-vue.css"; // base style
import TopBar from "@/components/altrama/TopBar";
import Button from "@/components/Inputs/Button.vue";
const pickImageHelper =
  require("@/components/altrama/richeditor/image-picker").default;

import LangSwitcher from "@/components/altrama/LangSwitcher";
export default {
  components: {
    Tree: Tree.mixPlugins([Fold, Draggable]),
    TopBar,
    DragHandle,
    LevelSwitch,
    Button,
    LangSwitcher,
  },

  props: ["id", "path", "parent"],

  data() {
    return {
      loading: false,
      lang: "it",
      isValid: false,
      treeData: [],
      title: "",
      newCatLang: "it",
      newCatInfo: this.$contentTemplate({
        title: "",
        subtitle: "",
        link: "",
      }),
    };
  },

  provide() {
    return { parentValidator: this.$validator };
  },

  created: async function () {
    if (!(await this.$api.user.can("user-create"))) {
      this.$router.replace("/not-found");
      return;
    }

    this.updateResource();
  },
  watch: {
    id: function () {
      this.updateResource();
    },
  },

  methods: {
    deleteMenuItem(node) {
      if (
        confirm(
          "La rimozione della menuitem comporta la modifica dei contenuti ad essa associata. Vuoi continuare?"
        )
      ) {
        let self = this;
        this.$api.menu.remove(node.id).then((data) => {
          self.$notifyVue(
            "top",
            "center",
            data.status,
            data.data.message,
            "ti-check"
          );

          self.updateResource();
        });
      }
    },
    addMenuItem() {
      let self = this;

      let object = {
        parent_id: null,
        target: '_self',
        contents: this.newCatInfo,
      };

    this.$api.menu.save("new", object).then((data) => {
        self.newCatInfo = self.$contentTemplate({
          title: "",
          subtitle: "",
          link: "",
        });

        self.$notifyVue(
          "top",
          "center",
          data.status,
          data.data.message,
          "ti-check"
        );

        self.updateResource();
     });
    },
    saveNode(node) {
      let self = this;
      this.$api.menu.save(node.id, node).then((data) => {
        self.$notifyVue(
          "top",
          "center",
          data.status,
          data.data.message,
          "ti-check"
        );
      });
    },
    update(store) {
      let node = store.dragNode;

      node.order = store.targetPath[store.targetPath.length - 1];

      if (store.startPath[store.startPath.length - 1] < node.order) {
        node.order--;
      }

      let updateObj = false;
      let parent = this.$refs.tree.getNodeParentByPath(store.targetPath);
      if (parent === undefined) {
        updateObj = {};
        let index = 0;
        for (let itm of this.treeData) {
          this.treeData[index].order = index;
          updateObj[itm.id] = index;
          index++;
        }
      } else {
        updateObj = {};
        let index = 0;
        for (let itm of parent.children) {
          itm.order = index;
          updateObj[itm.id] = index;
          index++;
        }
      }

      if (updateObj) {
        let self = this;
        this.$api.menu.update_order(updateObj).then((data) => {
          self.$notifyVue(
            "top",
            "center",
            data.status,
            data.data.message,
            "ti-check"
          );
        });
        console.info(updateObj);
      }
    },
    ondragend(tree, store) {
      return (
        (store.startPath.length === 1 && store.targetPath.length === 1) ||
        (store.startPath.length === 2 &&
          store.targetPath.length === 2 &&
          store.startPath[0] == store.targetPath[0])
      );
    },
    eachDraggable(path, tree, store) {
      const node = tree.getNodeByPath(path);
      return node.level > 0;
    },
    eachDroppable(path, tree, store) {
      const node = tree.getNodeByPath(path);
      return node === undefined || node.level == 0;
    },
    pickImage(field) {
      pickImageHelper(this.$el, 1).then((details) => {
        if (details.length > 0) {
          field.id = details[0].id;
          field.url_thumb = details[0].url_thumb;
          field.url = details[0].url;
        }
      });
    },

    updateResource() {
      this.$api.menu.index(this.id).then((data) => {
        function walkTree(tree, callback) {
          for (let node of tree) {
            callback(node);
            if (node.children) {
              walkTree(node.children, callback);
            }
          }
          return tree;
        }

        if (data.status == "success") {
          let treeData = [];
          var obj = data.data;
          for (let item of obj.children) {
            item.index = treeData.length;
            treeData.push(item);
          }
          this.title = obj.title;
          this.treeData = walkTree(treeData, (node) => {
            node.edit = false;
            node.$folded = true;
          });
        }
      });
    },
  }, // methods
};
</script>
