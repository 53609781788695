var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('search-box',{on:{"change":_vm.filter},model:{value:(_vm.searchQuery.searchTerm),callback:function ($$v) {_vm.$set(_vm.searchQuery, "searchTerm", $$v)},expression:"searchQuery.searchTerm"}})],1),_c('div',{staticClass:"col-auto"},[_c('label',{attrs:{"for":"select-comune"}},[_vm._v("Comune")]),_c('city-combo',{attrs:{"province_id":_vm.searchQuery.provincia},on:{"city":_vm.filter},model:{value:(_vm.searchQuery.comune),callback:function ($$v) {_vm.$set(_vm.searchQuery, "comune", $$v)},expression:"searchQuery.comune"}})],1),_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn btn-default",staticStyle:{"margin-top":"28px"},on:{"click":function($event){return _vm.noFilter()}}},[_c('i',{staticClass:"ti ti-arrow-circle-up"}),_vm._v(" Annulla Filtri ")])])]),_c('div',{staticClass:"card"},[_c('vue-good-table',{ref:"generic-table",attrs:{"mode":"remote","styleClass":"vgt-table striped","totalRows":_vm.totalRecords,"title":"Lista","columns":_vm.columns,"rows":_vm.rows,"totalRecords":"","paginate":true,"lineNumbers":false,"pagination-options":{
                                enabled: true,
                                position: 'top',
                                nextLabel: 'Successivo',
                                prevLabel: 'Precedente',
                                rowsPerPageLabel: 'Righe per pagina',
                                dropdownAllowAll: false,
                                perPage: _vm.serverParams.pagination.currentPerPage,
                                perPageDropdown: _vm.serverParams.myPerPageDropdown,
                                setCurrentPage: _vm.serverParams.pagination.currentPage
                },"sort-options":{ enabled: true, },"selectOptions":{
                                enabled: _vm.entity_delete,
                                selectOnCheckboxOnly: true,
                                selectionInfoClass: 'generic-class',
                                selectionText: 'righe selezionate',
                                clearSelectionText: 'deseleziona',
                },"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'title')?_c('span',[_c('a',{attrs:{"target":"_blank","href":'https://shop.emiliaromagnawelcome.com/it/activity/' + props.row.id}},[_vm._v(" "+_vm._s(props.row.title)+" ")])]):_vm._e()]}}])},[(_vm.totalRecords == 0)?_c('div',{attrs:{"slot":"emptystate","id":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"vgt-center-align vgt-text-disabled"},[(!_vm.isLoading)?_c('span',[_vm._v("Nessun risultato disponibile")]):_vm._e()])]):_vm._e(),_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[(_vm.entity_delete)?_c('a',{on:{"click":function($event){return _vm.deleteAll()}}},[_c('p-button',{attrs:{"type":"primary"}},[_vm._v("Cancella Selezionati")])],1):_vm._e()])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }