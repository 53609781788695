<template>
    <div>
        <div class="row"><!-- barra di ricerca -->
            <div class="col-auto"><!-- cerca -->
                <search-box v-model="searchQuery.searchTerm"  @change="filter"></search-box>
            </div>
            <div class="col-auto"><!-- comune -->
                <label for="select-comune">Comune</label>
                <city-combo
                        :province_id="searchQuery.provincia"
                        v-model="searchQuery.comune"
                        @city="filter"
                >
                </city-combo>
            </div>
            <div class="col">  <button style="margin-top: 28px;" class="btn btn-default" @click="noFilter()"><i class="ti ti-arrow-circle-up"></i>
                            Annulla Filtri
                        </button> </div>
            <!-- pulsanti azione -->
        </div>

        <div class="card">
            <vue-good-table
                    ref="generic-table"
                    mode="remote"
                    styleClass="vgt-table striped"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    title="Lista"
                    :columns="columns"
                    :rows="rows"
                    totalRecords
                    :paginate="true"
                    :lineNumbers="false"
                    :pagination-options="{
                                    enabled: true,
                                    position: 'top',
                                    nextLabel: 'Successivo',
                                    prevLabel: 'Precedente',
                                    rowsPerPageLabel: 'Righe per pagina',
                                    dropdownAllowAll: false,
                                    perPage: serverParams.pagination.currentPerPage,
                                    perPageDropdown: serverParams.myPerPageDropdown,
                                    setCurrentPage: serverParams.pagination.currentPage
                    }"
                    :sort-options="{ enabled: true, }"
                    :selectOptions="{
                                    enabled: entity_delete,
                                    selectOnCheckboxOnly: true,
                                    selectionInfoClass: 'generic-class',
                                    selectionText: 'righe selezionate',
                                    clearSelectionText: 'deseleziona',
                    }"
                    :isLoading=isLoading
            >
                <div slot="emptystate" id="emptystate" v-if="totalRecords == 0">
                    <div class="vgt-center-align vgt-text-disabled">
                        <span v-if="!isLoading">Nessun risultato disponibile</span>
                    </div>
                </div>

                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'title'">
                       <a target="_blank" :href="'https://shop.emiliaromagnawelcome.com/it/activity/' + props.row.id"> {{ props.row.title }} </a>
                    </span>
                </template>
                <div slot="selected-row-actions">
                    <a v-on:click="deleteAll()" v-if="entity_delete">
                        <p-button type="primary">Cancella Selezionati</p-button>
                    </a>
                </div>
            </vue-good-table> <!-- chiusura componente -->
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    import CategoryBox from "@/components/altrama/CategoryBox";
    import StatusesBox from "@/components/altrama/StatusesBox";
    import ProvinceCombo from "@/components/altrama/ProvinceCombo";
    import CityCombo from "@/components/altrama/CityCombo";
    import SearchBox from "@/components/altrama/SearchBox";
    import StatusesSpan from "@/components/altrama/StatusesSpan";

    let moment = require('moment');
    const tableColumns = ["Id", "Cognome", "Email"]; //mantenere per i campi tolowercase header tabella
    var tableData = [];
    var self = this;

    export default {
        components: {

            CategoryBox,
            StatusesBox,
            ProvinceCombo,
            CityCombo,
            SearchBox,
            StatusesSpan
        },

        data() {
            return {
                table1: {
                    title: "Elenco",
                    columns: [...tableColumns],
                    data: [...tableData],
                    route: "operations/trekksoft",
                },

                columns: [
                    {
                        label: 'ID',
                        field: 'id',
                        type: 'number',
                        html: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                        sortable: true,
                    },
                    {
                        label: 'TITOLO',
                        field: 'title',
                        filterable: true,
                        sortable: false,
                    },

                    {
                        label: "COMUNE",
                        field: "city",
                        filterable: false,
                        sortable: false
                    },
                    {
                        label: 'DATA',
                        field: 'created_at',
                        filterable: true,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                        sortable: false,
                    }
                ],
                entity_create: false,
                entity_delete: false,
                rows: [],
                totalRecords: 0,
                serverParams: {
                    columnFilters: {},
                    sort: {
                        field: 'id',
                        type: 'desc',
                    },
                    pagination: {
                        currentPage: 1,
                        currentPerPage: 50,
                    },
                    myPerPageDropdown: [10, 20, 30, 40, 50],
                },
                isLoading: false,
                searchQuery: {
                    destinazione: {'id':null}
                },
                langs:this.$available_langs_code,
            };
        },

        created: function () {
            if (this.$api.user.can("event-view") == false) {
                this.$router.replace("/not-found");
                return;
            }
            this.entity_create = this.$api.user.can(this.table1.route + "-create");
            this.entity_delete = this.$api.user.can(this.table1.route + "-delete");
        },
        mounted: function () {
            if (localStorage.getItem('opendata_searchQuery')) { // parametri di ricerca
                this.searchQuery = this.getSearchFilter();
            }
            if (localStorage.getItem('opendata_serverParams')) { // parametri di tabella
                this.serverParams = this.getParameterFilter();
            }
            this.updateResource();
        },
        methods: {
            filter(comune) {
                this.serverParams.pagination.currentPage = 1;
                this.updateResource();
            },

            noFilter() {
                localStorage.removeItem("opendata_searchQuery");
                localStorage.removeItem("opendata_serverParams");

                this.serverParams = {
                    columnFilters: {},
                    sort: {
                        field: "id",
                        type: "desc"
                    },
                    pagination: {
                        currentPage: 1,
                        currentPerPage: 50
                    },
                    myPerPageDropdown: [10, 20, 30, 40, 50]
                }

                this.searchQuery = {
                    destinazione: {'id':null}
                }

                const query = this.searchQuery;
                for (var key in query) {
                    query[key] = -1;
                }
                query['searchTerm'] = '';
                query['stato'] = -1;
                query['provincia'] = -1;
                this.updateResource();
            },
            saveParameterFilter() {
                localStorage.setItem(
                    "opendata_searchQuery",
                    JSON.stringify(this.searchQuery)
                );
                localStorage.setItem(
                    "opendata_serverParams",
                    JSON.stringify(this.serverParams)
                );
            },
            saveSearchFilter() { // salva parametri di ricerca
                localStorage.setItem('opendata_searchQuery', JSON.stringify(this.searchQuery));
            },
            getSearchFilter() { // prende parametri di ricerca
                return JSON.parse(localStorage.getItem('opendata_searchQuery'));
            },
            getParameterFilter() { // prende parametri di tabella
                return JSON.parse(localStorage.getItem('opendata_serverParams'));
            },
            onPerPageChange(params) { // risultati per pagina
                console.log('onPerPageChange');
                console.log(params);
                this.serverParams.pagination = params;
                this.updateResource();
            },
            onPageChange(params) { // risultati nel numero di pagina della paginazione (Es: pag 2 di 5)
                console.log('onPageChange');
                console.log(params);
                this.serverParams.pagination = params;
                this.updateResource();
            },
            onSortChange(params) { // ordinamento risultati per campo
                console.log('onSortChange');
                console.log(params[0]);
                this.serverParams.sort = params[0];
                this.updateResource();
            },

            async updateResource() {
                const self = this;
                self.rows = [];
                self.isLoading = true;

                var typeSort = '+';
                if (this.serverParams.sort.type == "desc")
                    typeSort = '-';

                this.saveParameterFilter();

                await axios({
                    method: 'GET',
                    url: this.$url + this.table1.route,
                    headers: this.$config.axiosHeaders(),
                    params: {
                        keywords: self.searchQuery.searchTerm,
                        provincia: self.searchQuery.provincia,
                        comune: self.searchQuery.comune ? (typeof self.searchQuery.comune === 'object' ? self.searchQuery.comune.id : self.searchQuery.comune) : '',
                        orderBy: typeSort + self.serverParams.sort.field,
                        page: self.serverParams.pagination.currentPage,
                        limit: self.serverParams.pagination.currentPerPage
                    }
                }).then(function (response) {
                    self.totalRecords = response.data.total;
                    self.rows = [];
                    var obj = response.data.data;
                  for( let entity of obj){

                        self.rows.push({
                            id: entity.trekksoft_id,
                            title: entity.title,
                            city: entity.location_name,
                            created_at: entity.date,
                            contents: entity.description
                        });
                    }
                }).catch(function (error) {
                    self.$processError(error);

                })
                    .finally(
                        () => self.isLoading = false
                    );
            }
        },
    };
</script>
