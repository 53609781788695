var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('search-box',{model:{value:(_vm.searchQuery.searchTerm),callback:function ($$v) {_vm.$set(_vm.searchQuery, "searchTerm", $$v)},expression:"searchQuery.searchTerm"}})],1),_c('div',{staticClass:"col-auto"},[_c('statuses-box',{attrs:{"list":"true"},model:{value:(_vm.searchQuery.stato),callback:function ($$v) {_vm.$set(_vm.searchQuery, "stato", $$v)},expression:"searchQuery.stato"}})],1),_c('div',{staticClass:"col-auto"},[_c('label',[_vm._v("Azioni")]),_c('div',{staticClass:"btn-toolbar mr-2",attrs:{"role":"toolbar","aria-label":"Bottoni azione"}},[_c('div',{staticClass:"btn-group mb-2 mr-2",attrs:{"role":"group","aria-label":"Azioni"}},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.filter()}}},[_c('i',{staticClass:"ti ti-filter"}),_vm._v(" Filtra ")]),_c('button',{staticClass:"btn btn-default",on:{"click":function($event){return _vm.noFilter()}}},[_c('i',{staticClass:"ti ti-arrow-circle-up"}),_vm._v(" Annulla Filtri ")])]),(_vm.entity_create)?_c('router-link',{staticClass:"btn btn-success mb-2",attrs:{"tag":"button","type":"button","to":{ name: 'Gestione Viaemilia', params: { id:'new',path:_vm.table1.route}}}},[_c('i',{staticClass:"ti ti-plus"}),_vm._v(" Nuovo Itinerario Via Emilia ")]):_vm._e()],1)])]),_c('div',{staticClass:"card"},[_c('vue-good-table',{ref:"generic-table",attrs:{"mode":"remote","styleClass":"vgt-table striped","totalRows":_vm.totalRecords,"title":"Lista Itinerari","columns":_vm.columns,"rows":_vm.rows,"totalRecords":"","paginate":true,"lineNumbers":false,"pagination-options":{
                            enabled: true,
                            position: 'top',
                            nextLabel: 'Successivo',
                            prevLabel: 'Precedente',
                            rowsPerPageLabel: 'Righe per pagina',
                            dropdownAllowAll: false,
                            perPage: _vm.serverParams.pagination.currentPerPage,
                            perPageDropdown: _vm.serverParams.myPerPageDropdown,
                            setCurrentPage: _vm.serverParams.pagination.currentPage
                },"sort-options":{ enabled: true, },"selectOptions":{
                    enabled: _vm.entity_delete,
                    selectOnCheckboxOnly: true,
                    selectionInfoClass: 'generic-class',
                    selectionText: 'righe selezionate',
                    clearSelectionText: 'deseleziona',
                },"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('action-table',{attrs:{"name":"Gestione Viaemilia","path":_vm.table1.route,"id":props.row.id}})],1):(props.column.field == 'active')?_c('span',[_c('ul',{staticClass:"lists"},_vm._l((_vm.langs),function(lang,langIndex){return _c('li',{key:langIndex},[_c('statuses-span',{attrs:{"contents":(props.row.contents[lang] ?props.row.contents[lang].status : -1)}})],1)}),0)]):(props.column.field == 'tema')?_c('span',[_c('ul',{staticClass:"lists"},_vm._l((props.row.tema),function(item){return _c('li',{key:item.id},[_vm._v(_vm._s(item.name))])}),0)]):(props.column.field == 'target')?_c('span',[_c('ul',{staticClass:"lists_noborders"},_vm._l((props.row.target),function(item){return _c('li',{key:item.id},[_vm._v(_vm._s(item.name))])}),0)]):(props.column.field == 'flag')?_c('span',[_c('flag-available-content-language',{attrs:{"align":1,"contents":props.row.contents}})],1):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}])},[(_vm.totalRecords == 0)?_c('div',{attrs:{"slot":"emptystate","id":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"vgt-center-align vgt-text-disabled"},[(!_vm.isLoading)?_c('span',[_vm._v("Nessun risultato disponibile")]):_vm._e()])]):_vm._e(),_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[(_vm.entity_delete)?_c('a',{on:{"click":function($event){return _vm.deleteAll()}}},[_c('p-button',{attrs:{"type":"primary"}},[_vm._v("Cancella Selezionati")])],1):_vm._e()])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }