<template>
  <div>
    <div class="row">
      <!-- main -->
      <div class="col-md-4 mb-2">
        <h3>
          <b>Interessi - {{ name_interest }}</b>
        </h3>
      </div>
    </div>
    <div class="row mb-2">
      <!-- barra di ricerca -->
      <div class="col-auto">
        <!-- cerca -->
        <search-box v-model="searchQuery.searchTerm"></search-box>
      </div>
      <div class="col-auto">
        <!-- interessi -->
        <category-box
          :mainCategory="mainCategory"
          v-model="searchQuery.tema"
          category-name="Interessi"
        >
        </category-box>
      </div>
      <div class="col-auto">
        <!-- stato -->
        <statuses-box list="true"  v-model="searchQuery.stato"></statuses-box>
      </div>
      <div class="col-auto">
        <!-- destinazioni -->
        <category-box
          :mainCategory="2"
          v-model="searchQuery.destinazione"
          category-name="Destinazione"
        >
        </category-box>
      </div>
      <div class="col-auto">
        <!-- provincia -->
        <province-combo v-model="searchQuery.provincia"></province-combo>
      </div>
      <div class="col-auto">
        <!-- comune -->
        <label for="select-comune">Comune</label>
        <city-combo
          :province_id="searchQuery.provincia"
          v-model="searchQuery.comune"
        >
        </city-combo>
      </div>
      <div class="col-auto">
        <label> Azioni </label>
        <div
          class="btn-toolbar mr-2"
          role="toolbar"
          aria-label="Bottoni azione"
        >
          <div class="btn-group mb-2 mr-2" role="group" aria-label="Azioni">
            <button class="btn btn-primary" @click="filter()">
              <i class="ti ti-filter"></i> Filtra
            </button>
            <button class="btn btn-default" @click="noFilter()">
              <i class="ti ti-arrow-circle-up"></i>
              Annulla Filtri
            </button>
          </div>
          <router-link
            tag="button"
            type="button"
            class="btn btn-success mb-2"
            :to="{
              name: 'Gestione Interessi',
              params: { id: 'new', path: table1.route, theme: Number(main) },
            }"
            v-if="entity_create"
          >
            <i class="ti ti-plus"></i> Nuovo Interesse
          </router-link>
        </div>
      </div>
    </div>
    <div class="card">
      <vue-good-table
        ref="generic-table"
        mode="remote"
        styleClass="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        title="Interessi"
        :columns="columns"
        :rows="rows"
        totalRecords
        :paginate="true"
        :lineNumbers="false"
        :pagination-options="{
          enabled: true,
          position: 'top',
          nextLabel: 'Successivo',
          prevLabel: 'Precedente',
          rowsPerPageLabel: 'Righe per pagina',
          dropdownAllowAll: false,
          perPage: serverParams.pagination.currentPerPage,
          perPageDropdown: serverParams.myPerPageDropdown,
          setCurrentPage: serverParams.pagination.currentPage,
        }"
        :sort-options="{ enabled: true }"
        :selectOptions="{
          enabled: entity_delete,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'generic-class',
          selectionText: 'righe selezionate',
          clearSelectionText: 'deseleziona',
        }"
        :isLoading="isLoading"
      >
        <div class="vgt-center-align vgt-text-disabled">
          <span v-if="!isLoading">Nessun risultato disponibile</span>
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'action'">
            <action-table
              name="Gestione Interessi"
              :path="table1.route"
              :id="props.row.id"
              :theme="Number(main)"
              type="interessi"
              :draft="props.row.draft"
              :revision="props.row.revision"
            >
            </action-table>
          </span>
          <span v-else-if="props.column.field == 'status'">
            <ul class="lists">
              <li v-for="(lang, langIndex) in langs" :key="langIndex">
                <statuses-span
                  :contents="props.row.revision_statuses[lang] ? props.row.revision_statuses[lang].status : (props.row.contents[lang] ?props.row.contents[lang].status : -1)"
                ></statuses-span>
              </li>
            </ul>
          </span>
          <span v-else-if="props.column.field == 'flag'">
            <flag-available-content-language
              :align="1"
              :contents="props.row.contents"
            >
            </flag-available-content-language>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <div slot="selected-row-actions">
          <a v-on:click="deleteAll()" v-if="entity_delete">
            <p-button type="primary">Cancella Selezionati</p-button>
          </a>
        </div> </vue-good-table
      ><!-- chiusura componente -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CategoryBox from "@/components/altrama/CategoryBox";
import StatusesBox from "@/components/altrama/StatusesBox";
import StatusesSpan from "@/components/altrama/StatusesSpan";
import ProvinceCombo from "@/components/altrama/ProvinceCombo";
import CityCombo from "@/components/altrama/CityCombo";
import SearchBox from "@/components/altrama/SearchBox";

const tableColumns = ["Id", "Cognome", "Email"]; //mantenere per i campi tolowercase header tabella
var tableData = [];
var self = this;

export default {
  components: {
    CategoryBox,
    StatusesBox,
    ProvinceCombo,
    CityCombo,
    SearchBox,
    StatusesSpan,
  },

  props: ["main", "name_interest"],

  data() {
    return {
      table1: {
        title: "Elenco",
        columns: [...tableColumns],
        data: [...tableData],
        route: "interessi",
      },
      columns: [
        {
          label: "ID",
          field: "id",
          type: "number",
          html: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "TITOLO",
          field: "title",
          filterable: true,
          sortable: false,
        },
        {
          label: "INTERESSE",
          field: "thematism",
          filterable: true,
          sortable: false,
        },
        {
          label: "STATO",
          field: "status",
          filterable: false,
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: "LINGUA",
          field: "flag",
          filterable: false,
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: "AZIONI",
          field: "action",
          filterable: false,
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
        },
      ],
      entity_create: false,
      entity_delete: false,
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        pagination: {
          currentPage: 1,
          currentPerPage: 50,
        },
        myPerPageDropdown: [10, 20, 30, 40, 50],
      },
      name: "Interessi",
      isLoading: false,
      searchQuery: {
        searchTerm: "",
        tema: { id: null },
        comune: { id: null },
        provincia: -1,
        stato: -1,
        destinazione: { id: null },
      },
      mainCategory: 0,
      langs:this.$available_langs_code,
    };
  },

  created: function () {
    if (this.$api.user.can(this.table1.route + "-view") == false) {
      this.$router.replace("/not-found");
      return;
    }
    this.mainCategory = Number(this.$route.params.main);
    this.entity_create = this.$api.user.can(this.table1.route + "-create");
    this.entity_delete = this.$api.user.can(this.table1.route + "-delete");
  },
  mounted: function () {
    if (localStorage.getItem("interessi_searchQuery")) {
      // parametri di ricerca
      this.searchQuery = this.getSearchFilter();
    }
    if (localStorage.getItem("interessi_serverParams")) {
      // parametri di tabella
      this.serverParams = this.getParameterFilter();
    }
    this.updateResource();
  },
  watch: {
    main: {
      handler: function () {
        this.noFilter();
        location.reload();
      },
      deep: true,
    },
  },
  methods: {
    filter() {
      this.serverParams.pagination.currentPage = 1;
      this.updateResource();
    },
    noFilter() {
      localStorage.removeItem("interessi_searchQuery");
      localStorage.removeItem("interessi_serverParams");

      this.serverParams = {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        pagination: {
          currentPage: 1,
          currentPerPage: 50,
        },
        myPerPageDropdown: [10, 20, 30, 40, 50],
      };

      this.searchQuery = {
        searchTerm: "",
        tema: { id: null },
        comune: { id: null },
        provincia: -1,
        stato: -1,
        destinazione: { id: null },
      };

      const query = this.searchQuery;
      for (var key in query) {
        query[key] = { id: null };
      }
      query["searchTerm"] = "";
      query["stato"] = -1;
      query["provincia"] = -1;
      this.updateResource();
    },
    saveParameterFilter() {
      localStorage.setItem(
        "interessi_searchQuery",
        JSON.stringify(this.searchQuery)
      );
      localStorage.setItem(
        "interessi_serverParams",
        JSON.stringify(this.serverParams)
      );
    },
    saveSearchFilter() {
      // salva parametri di ricerca
      localStorage.setItem(
        "interessi_searchQuery",
        JSON.stringify(this.searchQuery)
      );
    },
    getSearchFilter() {
      // prende parametri di ricerca
      return JSON.parse(localStorage.getItem("interessi_searchQuery"));
    },
    getParameterFilter() {
      // prende parametri di tabella
      return JSON.parse(localStorage.getItem("interessi_serverParams"));
    },
    onPerPageChange(params) {
      // risultati per pagina
      console.log("onPerPageChange");
      console.log(params);
      this.serverParams.pagination = params;
      this.updateResource();
    },
    onPageChange(params) {
      // risultati nel numero di pagina della paginazione (Es: pag 2 di 5)
      console.log("onPageChange");
      console.log(params);
      this.serverParams.pagination = params;
      this.updateResource();
    },
    onSortChange(params) {
      // ordinamento risultati per campo
      console.log("onSortChange");
      console.log(params[0]);
      this.serverParams.sort = params[0];
      this.updateResource();
    },

    async updateResource() {
      var self = this;
      self.rows = [];
      self.isLoading = true;

      var typeSort = "+";
      if (this.serverParams.sort.type == "desc") {
        typeSort = "-";
      }

      this.saveParameterFilter();

      await axios({
        method: "GET",
        url: self.$url + this.table1.route,
        headers: this.$config.axiosHeaders(),
        params: {
          keywords: self.searchQuery.searchTerm,
          main: self.mainCategory,
          stato: self.searchQuery.stato,
          destinazione: self.searchQuery.destinazione.id,
          comune: self.searchQuery.comune.id,
          provincia: self.searchQuery.provincia,
          tema: self.searchQuery.tema.id,
          orderBy: typeSort + self.serverParams.sort.field,
          page: self.serverParams.pagination.currentPage,
          limit: self.serverParams.pagination.currentPerPage,
        },
      })
        .then(function (response) {
          self.totalRecords = response.data.meta.total;
          self.rows = [];
          var obj = response.data.data;

          for( let entity of obj){

            self.rows.push({
              id: entity.id,
              title: entity.contents.it.title,
              status: entity.contents,
              thematism: entity.theme !== null ? entity.theme.name : "assente",
              contents: entity.contents,
              draft: entity.draft,
              revision: entity.revision,
              revision_statuses: entity.revision_statuses,
            });
          }
        })
        .catch(function (error) {
          self.$processError(error);

        })
        .finally(() => (self.isLoading = false));
    },
    deleteAll: function () {
      if (confirm("Confermare cancellazione degli elementi selezionati?")) {
        var elenco = [];
        var selected = this.$refs["generic-table"].selectedRows;
        for (var i = 0; i < selected.length; i++) {
          elenco.push(selected[i].id);
        }
        var self = this;
        self.isLoading = true;

        axios({
          method: "DELETE",
          url: this.$url + this.table1.route + "/" + elenco,
          headers: this.$config.axiosHeaders(),
        })
          .then(function (response) {
            self.$notifyVue(
              "top",
              "center",
              "success",
              response.data.data.message,
              "ti-check"
            );
            self.updateResource();
          })
          .catch(function (error) {
            self.$processError(error);

          })
          .finally(() => (self.isLoading = false));
      }
    },
  }, // methods
};
</script>
