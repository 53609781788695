<template>
  <div>
    <div class="row">
      <!-- barra di ricerca -->

      <div class="col-auto">
        <!-- cerca -->
        <search-box v-model="searchQuery.searchTerm"></search-box>
      </div>
      <div class="col-auto">
        <!-- destinazione -->
        <category-combo
          :mainCategory="2"
          v-model="searchQuery.destinazione"
          category-name="Destinazione"
        >
        </category-combo>
      </div>
      <div class="col-auto">
        <!-- provincia -->
        <province-combo v-model="searchQuery.provincia"></province-combo>
      </div>
      <div class="col-auto">
        <!-- comune -->
        <label>Comune</label>
        <city-combo
          :province_id="searchQuery.provincia"
          v-model="searchQuery.comune"
        >
        </city-combo>
      </div>
      <div class="col-auto">
        <!-- stato -->
        <statuses-box list="true" v-model="searchQuery.stato"></statuses-box>
      </div>
      <div class="col-auto">
        <!-- pulsanti azione -->
        <label>Azioni</label>
        <div
          class="btn-toolbar mr-2"
          role="toolbar"
          aria-label="Bottoni azione"
        >
          <div class="btn-group mb-2 mr-2" role="group" aria-label="Azioni">
            <button class="btn btn-primary" @click="filter()">
              <i class="ti ti-filter"></i> Filtra
            </button>
            <button class="btn btn-default" @click="noFilter()">
              <i class="ti ti-arrow-circle-up"></i>
              Annulla Filtri
            </button>
          </div>
          <router-link
            tag="button"
            type="button"
            class="btn mb-2 btn-success"
            :to="{ name: 'Gestione News', params: { id: 'new' } }"
            v-if="entity_create"
          >
            <i class="ti ti-plus"></i> Nuova News
          </router-link>
        </div>
        <!-- ./filtri -->
      </div>
    </div>
    <!-- ./barra di ricerca -->

    <div class="card">
      <!-- tabella risultati -->
      <vue-good-table
        ref="generic-table"
        mode="remote"
        styleClass="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        title="News"
        :columns="columns"
        :rows="rows"
        totalRecords
        :paginate="true"
        :lineNumbers="false"
        :pagination-options="{
          enabled: true,
          position: 'top',
          nextLabel: 'Successivo',
          prevLabel: 'Precedente',
          rowsPerPageLabel: 'Righe per pagina',
          dropdownAllowAll: false,
          perPage: serverParams.pagination.currentPerPage,
          perPageDropdown: serverParams.myPerPageDropdown,
          setCurrentPage: serverParams.pagination.currentPage,
        }"
        :sort-options="{ enabled: true }"
        :selectOptions="{
          enabled: entity_delete,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'generic-class',
          selectionText: 'righe selezionate',
          clearSelectionText: 'deseleziona',
        }"
        :isLoading="isLoading"
      >
        <div slot="emptystate" id="emptystate" v-if="totalRecords == 0">
          <div class="vgt-center-align vgt-text-disabled">
            <span v-if="!isLoading">Nessun risultato disponibile</span>
          </div>
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'action'">
            <action-table
              name="Gestione News"
              :path="table1.route"
              :id="props.row.id"
              :draft="props.row.draft"
              :revision="props.row.revision"
            >
            </action-table>
          </span>
          <span v-else-if="props.column.field == 'status'">
                        <ul class="lists">
                            <li v-for="(lang, langIndex) in langs" :key="langIndex">
                                <statuses-span :contents="props.row.revision_statuses[lang] ? props.row.revision_statuses[lang].status : (props.row.contents[lang] ?props.row.contents[lang].status : -1)"></statuses-span>
                            </li>
                        </ul>
                    </span>
          <span v-else-if="props.column.field == 'flag'">
            <flag-available-content-language
              :align="1"
              :contents="props.row.contents"
            ></flag-available-content-language>
          </span>
        </template>
        <div slot="selected-row-actions">
          <a v-on:click="deleteAll()" v-if="entity_delete">
            <button class="btn btn-primary">
              <i class="ti ti-close"></i> Cancella Selezionati
            </button>
          </a>
        </div>
      </vue-good-table>
    </div>
  </div>
  <!-- ./tabella risultati -->
</template>

<script>
import axios from "axios";
import StatusesBox from "@/components/altrama/StatusesBox";
import CityCombo from "@/components/altrama/CityCombo";
import ProvinceCombo from "@/components/altrama/ProvinceCombo";
import CategoryCombo from "@/components/altrama/CategoryBox";
import SearchBox from "@/components/altrama/SearchBox";
import StatusesSpan from "@/components/altrama/StatusesSpan";

let moment = require("moment");
const tableColumns = ["Id", "Cognome", "Email"];
const tableData = [];

export default {
  components: {
    StatusesBox,
    CityCombo,
    ProvinceCombo,
    CategoryCombo,
    SearchBox,
    StatusesSpan,
  },

  data() {
    return {
      table1: {
        title: "News",
        name: "news",
        columns: [...tableColumns],
        data: [...tableData],
        route: "news",
      },
      columns: [
        {
          label: "ID",
          field: "id",
          type: "number",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true,
        },
        {
          label: "TITOLO",
          field: "title",
          filterable: true,
        },
        {
          label: "COMUNE",
          field: "comune",
          tdClass: "text-left",
          thClass: "text-left",
        },
        {
          label: "DATA PUB",
          field: "pub_date",
          tdClass: "text-left",
          thClass: "text-left",
        },
        {
          label: "STATO",
          field: "status",
          html: true,
          tdClass: "text-left",
          thClass: "text-left",
        },
        {
          label: "LINGUA",
          field: "flag",
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "AZIONI",
          field: "action",
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      entity_create: false,
      entity_delete: false,
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        pagination: {
          currentPage: 1,
          currentPerPage: 50,
        },
        myPerPageDropdown: [10, 20, 30, 40, 50],
      },
      isLoading: false,
      searchQuery: {},
      langs:this.$available_langs_code,
    };
  },

  created: function () {
    if (this.$api.user.can(this.table1.route + "-view") == false) {
      this.$router.replace("/not-found");
      return;
    }
    this.entity_create = this.$api.user.can(this.table1.route + "-create");
    this.entity_delete = this.$api.user.can(this.table1.route + "-delete");
  },

  mounted: function () {
    if (localStorage.getItem("news_searchQuery")) {
      // parametri di ricerca
      this.searchQuery = this.getSearchFilter();
    }
    if (localStorage.getItem("news_serverParams")) {
      // parametri di tabella
      this.serverParams = this.getParameterFilter();
    }
    this.updateResource();
  },

  methods: {
    filter() {
      this.serverParams.pagination.currentPage = 1;
      this.saveSearchFilter();
      this.updateResource();
    },

    noFilter() {
      localStorage.removeItem("news_searchQuery");
      localStorage.removeItem("news_serverParams");

      this.serverParams = {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        pagination: {
          currentPage: 1,
          currentPerPage: 50,
        },
        myPerPageDropdown: [10, 20, 30, 40, 50],
      };

      this.searchQuery = {
        searchTerm: "",
        comune: { id: null },
        provincia: -1,
        destinazione: { id: null },
        stato: -1,
      };

      const query = this.searchQuery;
      for (var key in query) {
        query[key] = -1;
      }
      query["searchTerm"] = "";

      this.updateResource();
    },

    saveParameterFilter() {
      localStorage.setItem(
        "news_searchQuery",
        JSON.stringify(this.searchQuery)
      );
      localStorage.setItem(
        "news_serverParams",
        JSON.stringify(this.serverParams)
      );
    },

    saveSearchFilter() {
      // salva parametri di ricerca
      localStorage.setItem(
        "news_searchQuery",
        JSON.stringify(this.searchQuery)
      );
    },

    getSearchFilter() {
      // prende parametri di ricerca
      return JSON.parse(localStorage.getItem("news_searchQuery"));
    },

    getParameterFilter() {
      // prende parametri di tabella
      return JSON.parse(localStorage.getItem("news_serverParams"));
    },

    onPerPageChange(params) {
      // risultati per pagina
      console.log("onPerPageChange");
      console.log(params);
      this.serverParams.pagination = params;
      this.updateResource();
    },

    onPageChange(params) {
      // risultati nel numero di pagina della paginazione (Es: pag 2 di 5)
      console.log("onPageChange");
      console.log(params);
      this.serverParams.pagination = params;
      this.updateResource();
    },

    onSortChange(params) {
      // ordinamento risultati per campo
      console.log("onSortChange");
      console.log(params[0]);
      this.serverParams.sort = params[0];
      this.updateResource();
    },

    async updateResource() {
      var self = this;
      self.rows = [];
      self.isLoading = true;

      var typeSort = String("+");
      if (this.serverParams.sort.type == "desc") {
        typeSort = String("-");
      }
      this.saveParameterFilter();

      await axios({
        method: "GET",
        url: this.$url + this.table1.route,
        headers: this.$config.axiosHeaders(),
        params: {
          keywords: self.searchQuery.searchTerm,
          destinazione:
            typeof self.searchQuery.destinazione === "object"
              ? self.searchQuery.destinazione.id
              : self.searchQuery.destinazione,
          provincia:
            typeof self.searchQuery.provincia === "object"
              ? self.searchQuery.provincia.id
              : self.searchQuery.provincia,
          comune:
            typeof self.searchQuery.comune === "object"
              ? self.searchQuery.comune.id
              : self.searchQuery.comune,
          stato: self.searchQuery.stato,
          orderBy: typeSort + self.serverParams.sort.field,
          page: self.serverParams.pagination.currentPage,
          limit: self.serverParams.pagination.currentPerPage,
        },
      })
        .then(function (response) {
          self.totalRecords = response.data.meta.total;
          self.rows = [];
          var obj = response.data.data;
         for( let news of obj){
            var comune = null;
            const comuniList = news.comune.map((c) => c.nome);
            if (comuniList.length == 1) {
               comune = comuniList[0];
            } else if (comuniList.length > 1) {
              comune = comuniList[0] + " +  altri";
            } else {
              comune = "nessuno";
            }

            self.rows.push({
              id: news.id,
              title: news.contents["it"].title,
              contents: news.contents,
              comune: comune,
              draft: news.draft,
              revision: news.revision,
              revision_statuses: news.revision_statuses,
              pub_date: moment(news.contents['it'].pub_date).format('DD-MM-YYYY')
            });
          }
        })
        .catch(function (error) {
          self.$processError(error);

        })
        .finally(() => (self.isLoading = false));
    },

    deleteAll: function () {
      if (confirm("Confermare cancellazione degli elementi selezionati?")) {
        var elenco = [];
        var selected = this.$refs["generic-table"].selectedRows;
        for (var i = 0; i < selected.length; i++) {
          elenco.push(selected[i].id);
        }
        var self = this;
        self.isLoading = true;

        axios({
          method: "DELETE",
          url: this.$url + this.table1.route + "/" + elenco,
          headers: this.$config.axiosHeaders(),
        })
          .then(function (response) {
            self.$notifyVue(
              "top",
              "center",
              "success",
              response.data.data.message,
              "ti-check"
            );
            self.updateResource();
          })
          .catch(function (error) {
            self.$processError(error);

          })
          .finally(() => (self.isLoading = false));
      }
    },
  },
};
</script>

<style scoped>
.lists ul,
li {
  margin-top: -10px;
  margin-bottom: -10px;
}
</style>
