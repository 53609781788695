<template>
  <div>
    <div class="row">
      <!-- barra di ricerca -->
      <div class="col-auto">
        <!-- cerca -->
        <search-box v-model="searchQuery.searchTerm"></search-box>
      </div>
      <div class="col-auto">
        <!-- stato -->
        <statuses-box list="true" v-model="searchQuery.stato"></statuses-box>
      </div>
      <div class="col-auto">
        <label> Azioni </label>
        <div class="btn-toolbar" role="toolbar" aria-label="Bottoni azione">
          <div class="btn-group mr-2" role="group" aria-label="Azioni">
            <button class="btn mb-2 btn-primary" @click="filter()">
              <i class="ti ti-filter"></i> Filtra
            </button>
            <button class="btn mb-2 btn-default" @click="noFilter()">
              <i class="ti ti-arrow-circle-up"></i> Annulla Filtri
            </button>
          </div>
          <router-link
            tag="button"
            type="button"
            class="btn btn-success mb-2 ml-2"
            :to="{ name: 'Gestione Licenza', params: { id: 'new' } }"
            v-if="entity_create"
          >
            <i class="ti ti-plus"></i> Nuova licenza
          </router-link>
        </div>
      </div>
    </div>
    <div class="card">
      <vue-good-table
        ref="generic-table"
        mode="remote"
        styleClass="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        title="Lista Licencei"
        :columns="columns"
        :rows="rows"
        totalRecords
        :paginate="true"
        :lineNumbers="false"
        :pagination-options="{
          enabled: true,
          position: 'top',
          nextLabel: 'Successivo',
          prevLabel: 'Precedente',
          rowsPerPageLabel: 'Righe per pagina',
          dropdownAllowAll: false,
          perPage: serverParams.pagination.currentPerPage,
          perPageDropdown: serverParams.myPerPageDropdown,
          setCurrentPage: serverParams.pagination.currentPage,
        }"
        :sort-options="{ enabled: true }"
        :selectOptions="{
          enabled: entity_delete,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'generic-class',
          selectionText: 'righe selezionate',
          clearSelectionText: 'deseleziona',
        }"
        :isLoading="isLoading"
      >
        <div slot="emptystate" id="emptystate" v-if="totalRecords == 0">
          <div class="vgt-center-align vgt-text-disabled">
            <span v-if="!isLoading">Nessun risultato disponibile</span>
          </div>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'action'">
            <action-table
              name="Gestione Licenza"
              :path="table1.route"
              :id="props.row.id"
              :draft="props.row.draft"
              :revision="props.row.revision"
            ></action-table>
          </span>
          <span v-else-if="props.column.field == 'status'">
            <ul class="lists">
              <li v-for="(lang, langIndex) in langs" :key="langIndex">
                <statuses-span
                  :contents="props.row.status[lang] ? props.row.status[lang].status : -1"
                ></statuses-span>
              </li>
            </ul>
          </span>
          <span v-else-if="props.column.field == 'flag'">
            <flag-available-content-language
              :align="1"
              :contents="props.row.contents"
            ></flag-available-content-language>
          </span>
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
        <div slot="selected-row-actions">
          <a v-on:click="deleteAll()" v-if="entity_delete">
            <button class="btn btn-primary">Cancella Selezionati</button>
          </a>
        </div>
      </vue-good-table>
      <!-- chiusura componente -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import StatusesBox from "@/components/altrama/StatusesBox";
import CityCombo from "@/components/altrama/CityCombo";
import ProvinceCombo from "@/components/altrama/ProvinceCombo";
import CategoryCombo from "@/components/altrama/CategoryBox";
import SearchBox from "@/components/altrama/SearchBox";
import StatusesSpan from "@/components/altrama/StatusesSpan";
import LangBox from "@/components/altrama/LangBox";
import DatePicker from "@/components/altrama/DatePicker";
import DestinationCombo from "@/components/altrama/DestinationCombo";

let moment = require("moment");
const tableColumns = [];
var tableData = [];
var self = this;

export default {
  components: {
    SearchBox,
    CategoryCombo,
    CityCombo,
    StatusesBox,
    ProvinceCombo,
    StatusesSpan,
    LangBox,
    DatePicker,
    DestinationCombo,
  },
  data() {
    return {
      table1: {
        title: "Elenco",
        columns: [...tableColumns],
        data: [...tableData],
        route: "licence",
      },
      columns: [
        {
          label: "ID",
          field: "id",
          type: "number",
          html: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "NOME",
          field: "title",
          sortable: false,
        },
        {
          label: "URL",
          field: "url",
          sortable: false,
        },
        {
          label: "STATO",
          field: "status",
          html: true,
          sortable: false,
        },
        {
          label: "LINGUA",
          field: "flag",
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: "AZIONI",
          field: "action",
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false,
        },
      ],
      entity_create: false,
      entity_delete: false,
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        pagination: {
          currentPage: 1,
          currentPerPage: 50,
        },
        myPerPageDropdown: [10, 20, 30, 40, 50],
      },
      isLoading: false,
      searchQuery: {
        comune: { id: null },
      },
      langs: this.$available_langs_code,
    };
  },

  created: function () {
    if (this.$api.user.can(this.table1.route + "-view") == false) {
      this.$router.replace("/not-found");
      return;
    }
    this.entity_create = this.$api.user.can(this.table1.route + "-create");
    this.entity_delete = this.$api.user.can(this.table1.route + "-delete");
  },

  mounted: function () {
    if (localStorage.getItem("licence_searchQuery")) {
      // parametri di ricerca
      this.searchQuery = this.getSearchFilter();
    }
    if (localStorage.getItem("licence_serverParams")) {
      // parametri di tabella
      this.serverParams = this.getParameterFilter();
    }
    this.updateResource();
  },

  methods: {
    filter() {
      this.serverParams.pagination.currentPage = 1;
      this.updateResource();
    },

    noFilter() {
      localStorage.removeItem("licence_searchQuery");
      localStorage.removeItem("licence_serverParams");

      this.serverParams = {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        pagination: {
          currentPage: 1,
          currentPerPage: 50,
        },
        myPerPageDropdown: [10, 20, 30, 40, 50],
      };

      this.searchQuery = {
        comune: { id: null },
      };
      this.updateResource();
    },

    saveParameterFilter() {
      localStorage.setItem(
        "licence_searchQuery",
        JSON.stringify(this.searchQuery)
      );
      localStorage.setItem(
        "licence_serverParams",
        JSON.stringify(this.serverParams)
      );
    },

    saveSearchFilter() {
      // salva parametri di ricerca
      localStorage.setItem(
        "licence_searchQuery",
        JSON.stringify(this.searchQuery)
      );
    },

    getSearchFilter() {
      // prende parametri di ricerca
      return JSON.parse(localStorage.getItem("licence_searchQuery"));
    },

    getParameterFilter() {
      // prende parametri di tabella
      return JSON.parse(localStorage.getItem("licence_serverParams"));
    },

    onPerPageChange(params) {
      // risultati per pagina
      console.log("onPerPageChange");
      console.log(params);
      this.serverParams.pagination = params;
      this.updateResource();
    },

    onPageChange(params) {
      // risultati nel numero di pagina della paginazione (Es: pag 2 di 5)
      console.log("onPageChange");
      console.log(params);
      this.serverParams.pagination = params;
      this.updateResource();
    },

    onSortChange(params) {
      // ordinamento risultati per campo
      console.log("onSortChange");
      console.log(params[0]);
      this.serverParams.sort = params[0];
      this.updateResource();
    },

    async updateResource() {
      let self = this;
      self.rows = [];
      self.isLoading = true;

      var typeSort = "+";
      if (this.serverParams.sort.type == "desc") {
        typeSort = "-";
      }

      this.saveParameterFilter();

      await axios({
        method: "GET",
        url: self.$url + this.table1.route,
        headers: self.$config.axiosHeaders(),
        params: {
          keywords: self.searchQuery.searchTerm,
          lang: self.searchQuery.lang,
          stato: self.searchQuery.stato,
          orderBy: typeSort + self.serverParams.sort.field,
          page: self.serverParams.pagination.currentPage,
          limit: self.serverParams.pagination.currentPerPage,
        },
      })
        .then(function (response) {
          self.totalRecords = response.data.meta.total;
          self.rows = [];
          var obj = response.data.data;
          for( let entity of obj){

            const revision_statuses = entity.revision_statuses['it'] ? entity.revision_statuses: entity.contents;
            const status = entity.contents['it'] ? entity.contents : entity.revision_statuses;
            const item = {
              id: entity.id,
              title: entity.contents.it.title,
              url: entity.contents.it.url,
              status: status,
              draft: entity.draft,
              revision: entity.revision,
              revision_statuses: revision_statuses,
            }
            self.rows.push(item);
          }
        })
        .catch(function (error) {
          self.$processError(error);

        })
        .finally(() => (self.isLoading = false));
    },
    deleteAll: function () {
      if (confirm("Confermare cancellazione degli elementi selezionati?")) {
        var elenco = [];
        var selected = this.$refs["generic-table"].selectedRows;
        for (var i = 0; i < selected.length; i++) {
          elenco.push(selected[i].id);
        }
        var self = this;
        self.isLoading = true;

        axios({
          method: "DELETE",
          url: this.$url + this.table1.route + "/" + elenco,
          headers: this.$config.axiosHeaders(),
        })
          .then(function (response) {
            self.$notifyVue(
              "top",
              "center",
              "success",
              response.data.data.message,
              "ti-check"
            );
            self.updateResource();
          })
          .catch(function (error) {
            self.$processError(error);

          })
          .finally(() => (self.isLoading = false));
      }
    },
  }, // methods
};
</script>
