var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-4 text-right"},[(_vm.role_create)?_c('router-link',{attrs:{"to":{ name: 'Role',  params: { id:'new', path:_vm.route}}}},[_c('p-button',{attrs:{"addon-left-icon":"ti ti-search","type":"success"}},[_vm._v("Aggiungi")])],1):_vm._e()],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{ref:"generic-table-roles",attrs:{"mode":"remote","totalRows":_vm.totalRecords,"title":"","columns":_vm.columns,"rows":_vm.rows,"rowStyleClass":"rowStyleClass","totalRecords":"","paginate":true,"lineNumbers":false,"pagination-options":{
                                enabled: false,
                                position: 'top',
                                nextLabel: 'Successivo',
                                prevLabel: 'Precedente',
                                rowsPerPageLabel: 'Righe per pagina',
                                dropdownAllowAll: false,
                                perPage: _vm.myPerPage,
                                perPageDropdown: _vm.myPerPageDropdown,
                },"sort-options":{ enabled: true, },"selectOptions":{
                                enabled: true,
                                selectOnCheckboxOnly: true,
                                selectionInfoClass: 'generic-class',
                                selectionText: 'righe selezionate',
                                clearSelectionText: 'deseleziona',
                },"isLoading":_vm.myLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',{staticStyle:{"text-align":"center"}},[_c('action-table',{attrs:{"name":"Role","path":'users/roles',"id":props.row.id}})],1):_c('span',{staticStyle:{"text-align":"center","width":"100%"}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate","id":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.emptystate_msg)+" ")]),_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteAll()}}},[_c('p-button',{attrs:{"type":"primary"}},[_vm._v("Cancella Selezionati")])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }