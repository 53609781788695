var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('search-box',{model:{value:(_vm.searchQuery.searchTerm),callback:function ($$v) {_vm.$set(_vm.searchQuery, "searchTerm", $$v)},expression:"searchQuery.searchTerm"}})],1),_c('div',{staticClass:"col-auto ml-auto"},[_c('div',{staticClass:"form-group"},[_c('label',{staticStyle:{"opacity":"0"}},[_vm._v(" Azione ")]),_c('br'),(_vm.entity_create)?_c('router-link',{staticClass:"btn mb-2 btn-success",attrs:{"tag":"button","type":"button","to":{ name: 'Gestione Webcam',  params: { id:'new',path:_vm.table1.route}}}},[_c('i',{staticClass:"ti ti-plus"}),_vm._v(" Aggiungi ")]):_vm._e()],1)])]),_c('div',{staticClass:"card"},[_c('vue-good-table',{ref:"generic-table",attrs:{"mode":"remote","styleClass":"vgt-table striped","totalRows":_vm.totalRecords,"title":"Lista IAT","columns":_vm.columns,"rows":_vm.rows,"totalRecords":"","paginate":true,"lineNumbers":false,"pagination-options":{
                                enabled: true,
                                position: 'top',
                                nextLabel: 'Successivo',
                                prevLabel: 'Precedente',
                                rowsPerPageLabel: 'Righe per pagina',
                                dropdownAllowAll: false,
                                perPage: _vm.serverParams.pagination.currentPerPage,
                                perPageDropdown: _vm.serverParams.myPerPageDropdown,
                                setCurrentPage: _vm.serverParams.pagination.currentPage
                },"sort-options":{ enabled: true, },"selectOptions":{
                                enabled: _vm.entity_delete,
                                selectOnCheckboxOnly: true,
                                selectionInfoClass: 'generic-class',
                                selectionText: 'righe selezionate',
                                clearSelectionText: 'deseleziona',
                },"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('action-table',{attrs:{"name":"Gestione Webcam","path":_vm.table1.route,"id":props.row.id}})],1):(props.column.field == 'status')?_c('span',[_c('ul',{staticClass:"lists"},_vm._l((['it']),function(lang,langIndex){return _c('li',{key:langIndex},[_c('statuses-span',{attrs:{"contents":props.row.revision_statuses[lang] ? props.row.revision_statuses[lang].status : (props.row.contents[lang] ?props.row.contents[lang].status : -1)}})],1)}),0)]):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]}}])},[(_vm.totalRecords == 0)?_c('div',{attrs:{"slot":"emptystate","id":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"vgt-center-align vgt-text-disabled"},[(!_vm.isLoading)?_c('span',[_vm._v("Nessun risultato disponibile")]):_vm._e()])]):_vm._e(),_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[(_vm.entity_delete)?_c('a',{on:{"click":function($event){return _vm.deleteAll()}}},[_c('p-button',{attrs:{"type":"primary"}},[_vm._v("Cancella Selezionati")])],1):_vm._e()])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }