<template>
    <div>
        <div class="row">
            <div class="col-md-12 mb-4 text-right">
                <router-link :to="{ name: 'Role',  params: { id:'new', path:route}}" v-if="role_create">
                    <p-button addon-left-icon="ti ti-search" type="success">Aggiungi</p-button>
                </router-link>
            </div>
        </div>

        <div class="card">
            <vue-good-table
                    ref="generic-table-roles"
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    title=""
                    :columns="columns"
                    :rows="rows"
                    rowStyleClass='rowStyleClass'
                    totalRecords
                    :paginate="true"
                    :lineNumbers="false"
                    :pagination-options="{
                                    enabled: false,
                                    position: 'top',
                                    nextLabel: 'Successivo',
                                    prevLabel: 'Precedente',
                                    rowsPerPageLabel: 'Righe per pagina',
                                    dropdownAllowAll: false,
                                    perPage: myPerPage,
                                    perPageDropdown: myPerPageDropdown,
                    }"
                    :sort-options="{ enabled: true, }"
                    :selectOptions="{
                                    enabled: true,
                                    selectOnCheckboxOnly: true,
                                    selectionInfoClass: 'generic-class',
                                    selectionText: 'righe selezionate',
                                    clearSelectionText: 'deseleziona',
                    }"
                    :isLoading=myLoading
            >
                <div slot="emptystate" id="emptystate">
                    {{emptystate_msg}}
                </div>

                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'action'" style="text-align:center;">
                       <action-table name="Role" :path="'users/roles'" :id="props.row.id"></action-table>
                    </span>
                    <span v-else style="text-align:center;width:100%;">
                        {{props.formattedRow[props.column.field]}}
                    </span>
                </template>

                <div slot="selected-row-actions">
                    <a href="#" v-on:click="deleteAll()">
                        <p-button type="primary">Cancella Selezionati</p-button>
                    </a>
                </div>
            </vue-good-table>  <!-- chiusura componente -->
        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    var self = this;

    export default {
        
        data() {
            return {
                role_create:false,
               
                    title: "Elenco Utenti",
                    subTitle: "",
                   
                    route: "users/roles",
                    classe: "utente-class",
                    messaggio: "",
                    see_msg: false,
               
                columns: [
                    {
                        label: 'ID',
                        field: 'id',
                        type: 'number',
                        filterable: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                        sortable: true,
                    },
                    {
                        label: 'RUOLO',
                        field: 'title',
                        filterable: true,
                        tdClass: 'text-left',
                        thClass: 'text-left',
                        sortable: true,
                    },
                    {
                        label: 'AZIONE',
                        field: 'action',
                        filterable: false,
                        html: true,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                        sortable: false,
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    columnFilters: {},
                    sort: {
                        field: 'id',
                        type: 'desc',
                    },
                    page: 1,
                    limit: 25
                },
                name: 'my-table',
                p_search: '',
                emptystate_msg: '',
                myPerPageDropdown: [10, 20, 30, 40, 50],
                myPerPage: 10,
                myLoading: false
            };
        },

        created: async function () {                       
            if ( !(await this.$api.user.isAdmin() ) ) {
                this.$router.replace("/not-found");                
            }

            this.role_create = await this.$api.user.can("role-create");

            var navigationInfo = this.$getNavigationInfo();
            var route = this.route.replace('/', '-');
            if (navigationInfo != null && navigationInfo[0] == route) {
                this.serverParams.page = Number(navigationInfo[1]);
                this.serverParams.perPage = Number(navigationInfo[2]);
                this.serverParams.limit = Number(navigationInfo[2]);
                this.serverParams.sort.field = navigationInfo[3];
                this.serverParams.sort.type = navigationInfo[4];
                this.myPerPage = Number(navigationInfo[2]);
            } else {
                this.serverParams.page = 1;
                this.serverParams.perPage = 10;
            }
            this.updateResource(this.serverParams.page, this.serverParams.perPage);

        },       
        methods: {
            updateResource(page_start, per_page) {
                var self = this;
                self.rows = [];
                self.emptystate_msg = "";
                self.myLoading = true;

                var typeSort = '+';
                if (this.serverParams.sort.type == "desc")
                    typeSort = '-';

                axios({
                    method: 'get',
                    url: self.$url + 'user/roles',
                    headers: this.$config.axiosHeaders(),
                    params: {}

                }).then(function (response) {
                    if (response.data.status == 'success') {
                        if (response.data.data.length == 0) {
                            self.emptystate_msg = "Nessun risultato trovato";
                        }

                        self.totalRecords = response.data.data.length;
                        self.rows = [];
                        var obj = response.data.data;
                        
                        for( let ruolo of obj){  
                            self.rows.push({
                                id: ruolo.id,
                                title: ruolo.title
                            });
                        }
                        self.see_msg = true;
                        self.myLoading = false;
                    }
                }).catch(function (error) {
                    console.error(error.response);
                    self.$processError(error);
                });
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
                var route = this.route.replace('/', '-');
                this.$setNavigationInfo(route, this.serverParams.page, this.serverParams.perPage, this.serverParams.sort.field, this.serverParams.sort.type);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems(params.currentPage, params.currentPerPage);
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems(params.currentPage, params.currentPerPage);
            },

            onSortChange(params) {
                this.updateParams({
                    sort: {
                        type: params[0].type,
                        field: params[0].field,
                    },
                });
                this.loadItems(params.currentPage, params.currentPerPage);
            },

            onSortChangeOld(params) {
                this.updateParams({
                    sort: {
                        type: params.sortType,
                        field: this.columns[params.columnIndex].field,
                    },
                });
                this.loadItems(params.currentPage, params.currentPerPage);
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems(params.currentPage, params.currentPerPage);
            },

            loadItems(page, per_page) {
                this.updateResource(page, per_page);
            },

            deleteAll: function () {

                if (confirm('Confermare cancellazione degli elementi selezionati?')) {
                    var elenco = [];
                    var selected = this.$refs['generic-table-roles'].selectedRows;
                    for (var i = 0; i < selected.length; i++) {
                        elenco.push(selected[i].id);
                    }
                    var self = this;
                    

                    axios({
                        method: 'delete',
                        url: self.$url + self.route + '/' + elenco,
                        headers: this.$config.axiosHeaders(),
                        params: {
                            id: elenco,
                        }
                    }).then(function (response) {
                        var color = 2;
                        var icon = "ti-check";
                        if (response.data.status == 'success') {
                            self.loadItems(self.serverParams.page, self.serverParams.limit);
                        } else {
                            color = 3;
                            icon = "ti-close";
                        }
                        self.$notifyVue('top', 'center', 'success', response.data.data.message, icon);
                    }).catch(function (error) {
                        self.$notifyVue('top', 'center', 'success', response.data.message, icon);
                        self.$processError(error);
                    });
                }
            },

            search(e) {
                var self = this;
                
                self.emptystate_msg = "";
                self.myLoading = true;

                if (self.p_search.length > 0) {
                    axios({
                        method: 'post',
                        url: this.$url + this.route + '/search',
                        headers: this.$config.axiosHeaders(),
                        params: {
                            keywords: self.p_search,
                        }
                    }).then(function (response) {
                        if (response.data.status == 'success') {
                            self.rows = [];
                            self.totalRecords = response.data.meta.total;

                            if (response.data.meta.total == 0) {
                                self.emptystate_msg = "Nessun risultato trovato";
                            }

                            var obj = response.data.data;
                            for( let utente of obj){  
                                self.rows.push({
                                    id: utente.id,
                                    username: utente.username,
                                    last_name: utente.last_name,
                                    first_name: utente.first_name,
                                    company: utente.company,
                                    email: utente.email,
                                    roles: utente.roles
                                });
                            }
                            self.see_msg = true;
                            self.myLoading = false;
                        }
                    }).catch(function (error) {
                        self.$processError(error);
                    });
                } else {
                    this.loadItems(this.serverParams.page, this.serverParams.limit);
                }
            },
            asyncFindString: function () {
                if (this.p_search.length >= 1) {
                    //servono almeno 3 caratteri per avviare la ricerca
                    self = this;
                    clearTimeout(self.myVarTimeout); //annullo attesa precedente
                    self.myVarTimeout = setTimeout(function () {
                        self.search(self.p_search);
                    }, 750);
                } //controllo lunghezza stringa
                else if (this.p_search.length == 0)
                    this.updateResource();
            },
        }
    };
</script>

<style>
    .rowStyleClass {
        text-align: center;
    }
</style>