<template>
  <generic-list :options="options">
    <template v-slot:filters="{ query }">
      <div class="col-auto">
        <!-- destinazione -->
        <category-box
          :mainCategory="4"
          v-model="query.durata"
          category-name="Durata"
        ></category-box>
      </div>
      <div class="col-auto">
        <!-- interesse -->
        <category-box
          :mainCategory="5"
          v-model="query.tema"
          category-name="Interesse"
        ></category-box>
      </div>

      <div class="col-auto">
        <!-- target -->
        <category-box
          :mainCategory="6"
          v-model="query.target"
          category-name="Target"
        ></category-box>
      </div>
    </template>
  </generic-list>
</template>

<script>
import GenericList from "@/pages/Lists/GenericList.vue";

import SearchBox from "@/components/altrama/SearchBox";
import CategoryBox from "@/components/altrama/CategoryBox";
import StatusesBox from "@/components/altrama/StatusesBox";
import StatusesSpan from "@/components/altrama/StatusesSpan";

const baseRoute = "itinerary";
export default {
  components: {
    SearchBox,
    StatusesBox,
    StatusesSpan,
    CategoryBox,
    GenericList,
  },
  data() {
    return {
      options: {
        actions: {
          edit: {
            routeName: "Gestione Itinerario",
            path: baseRoute,
          },
          new: {
            title: "Nuovo Itinerario",
            route: "Gestione Itinerario",
          },
        },
        columns: [
          {
            label: "ID",
            field: "id",
            type: "number",
            html: false,
            tdClass: "text-center",
            thClass: "text-center",
            sortable: true,
          },
          {
            label: "TITOLO",
            field: "title",
            filterable: true,
            sortable: false,
          },
          {
            label: "STATO",
            field: "status",
            filterable: false,
            html: true,
            tdClass: "text-center",
            thClass: "text-center",
            sortable: false,
          },
        ],

        apiGetRoute: this.$url + baseRoute,
        apiDeleteRoute: this.$url + baseRoute,
        mapper: this.itemMapper,
      },
    };
  },

  methods: {
    itemMapper(item) {
      return {
        id: item.id,
        title: item.contents["it"].title,
        contents: item.contents,
      };
    },
  },
};
</script>
