<template>
  <div>
    <div class="w-100 p-4 row">
      <!-- barra di ricerca -->
      <div class="col">
        <label for="select-comune">Comune</label>
        <city-combo
          :province_id="searchQuery.provincia"
          v-model="searchQuery.comune"
          @city="filter"
        >
        </city-combo>
      </div>
      <div class="col">
        <button
          style="margin-top: 28px"
          class="btn btn-default"
          @click="noFilter()"
        >
          <i class="ti ti-arrow-circle-up"></i>
          Annulla Filtri
        </button>
      </div>
    </div>

    <!-- Test loading -->
    <div class="vgt-loading vgt-center-align" v-if="isLoading">
      <span class="vgt-loading__content">Loading...</span>
    </div>

    <div class="row">
      <div v-for="item in elements" :key="'item' + item.id" class="col-12">
        <div class="card">
          <div class="col-12">
            <div class="row">
              <div class="col-auto p-4">
                <img width="180" :src="item.images_gallery.thumb[0]" />
              </div>
              <div class="col p-4">
                <h2 class="m-0">{{ item.title }}</h2>
                <div class="w-100">
                  <strong
                    v-for="(cat, index) in item.tipologies"
                    :key="cat.id + cat.name"
                    >{{ cat.name
                    }}<span v-if="index !== item.tipologies.length - 1"
                      >,
                    </span>
                  </strong>
                  |
                  <strong
                    v-for="(cat, index) in item.targets"
                    :key="cat.id + cat.name"
                    >{{ cat.name
                    }}<span v-if="index !== item.targets.length - 1">, </span>
                  </strong>
                </div>
                <div class="w-100">
                  <strong>Prezzo: </strong>{{ item.price_amount
                  }}{{ item.price_currency }}
                </div>
                <div class="w-100">
                  {{ item.structure.name }} -
                  <span
                    v-for="(location, index) in item.locations"
                    :key="index + location.city_code"
                  >
                    {{ location.address }}, {{ location.city_name }}({{
                      location.province_code
                    }})<span v-if="index !== item.locations.length - 1">
                      |
                    </span>
                  </span>
                </div>
                Date : dal {{ parseDate(item.dates.start_date) }} al
                {{ parseDate(item.dates.end_date) }}
                <p>{{ item.abstract }}</p>

                <hr />
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      :name="'status' + item.id"
                      value="Non mi interessa"
                      v-model="item.status"
                      @change="updateStatus(item)"
                    />
                    <label class="form-check-label" for="inlineRadio1"
                      >Non mi interessa</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      :name="'status' + item.id"
                      value="Mi interessa"
                      v-model="item.status"
                      @change="updateStatus(item)"
                    />
                    <label class="form-check-label" for="inlineRadio2"
                      >Mi interessa</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      :name="'status' + item.id"
                      value="Copiato"
                      v-model="item.status"
                      @change="updateStatus(item)"
                    />
                    <label class="form-check-label" for="inlineRadio2"
                      >Copiato</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      :name="'status' + item.id"
                      value="non definito"
                      v-model="item.status"
                      @change="updateStatus(item)"
                    />
                    <label class="form-check-label" for="inlineRadio3"
                      >Non definito</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CategoryBox from "@/components/altrama/CategoryBox";
import StatusesBox from "@/components/altrama/StatusesBox";
import ProvinceCombo from "@/components/altrama/ProvinceCombo";
import CityCombo from "@/components/altrama/CityCombo";
import SearchBox from "@/components/altrama/SearchBox";
import StatusesSpan from "@/components/altrama/StatusesSpan";
import _ from "lodash";
import StatsCardVue from "@/components/Cards/StatsCard.vue";

let moment = require("moment");
const tableColumns = ["Id", "Cognome", "Email"]; //mantenere per i campi tolowercase header tabella
var tableData = [];
var self = this;

export default {
  components: {
    CategoryBox,
    StatusesBox,
    ProvinceCombo,
    CityCombo,
    SearchBox,
    StatusesSpan,
  },

  data() {
    return {
      elements: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        pagination: {
          currentPage: 1,
          currentPerPage: 50,
        },
        myPerPageDropdown: [10, 20, 30, 40, 50],
      },
      isLoading: false,
      searchQuery: {
        destinazione: { id: null },
      },
      langs:this.$available_langs_code,
    };
  },
  created: function () {
    if (this.$api.user.can("event-view") == false) {
      this.$router.replace("/not-found");
      return;
    }
  },
  mounted: function () {
    if (localStorage.getItem("opendata_searchQuery")) {
      // parametri di ricerca
      this.searchQuery = this.getSearchFilter();
    }
    if (localStorage.getItem("opendata_serverParams")) {
      // parametri di tabella
      this.serverParams = this.getParameterFilter();
    }
    this.updateResource();
  },
  methods: {
    async updateStatus(item) {
      var self = this;
      self.isLoading = true;
      await axios({
        method: "POST",
        url: this.$url + "operations/xpstatus",
        headers: this.$config.axiosHeaders(),
        params: {
          id: item.id,
          status: item.status,
        },
      })
        .then(function (response) {})
        .catch(function (error) {
          self.$processError(error);

        })
        .finally(() => (self.isLoading = false));
    },
    parseDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    filter(comune) {
      this.serverParams.pagination.currentPage = 1;
      this.searchQuery.comune = comune;
      this.updateResource();
    },

    noFilter() {
      localStorage.removeItem("opendata_searchQuery");
      localStorage.removeItem("opendata_serverParams");

      this.serverParams = {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        pagination: {
          currentPage: 1,
          currentPerPage: 50,
        },
        myPerPageDropdown: [10, 20, 30, 40, 50],
      };

      this.searchQuery = {
        destinazione: { id: null },
      };

      const query = this.searchQuery;
      for (var key in query) {
        query[key] = -1;
      }
      query["searchTerm"] = "";
      query["stato"] = -1;
      query["provincia"] = -1;
      this.updateResource();
    },
    saveParameterFilter() {
      localStorage.setItem(
        "opendata_searchQuery",
        JSON.stringify(this.searchQuery)
      );
      localStorage.setItem(
        "opendata_serverParams",
        JSON.stringify(this.serverParams)
      );
    },
    saveSearchFilter() {
      // salva parametri di ricerca
      localStorage.setItem(
        "opendata_searchQuery",
        JSON.stringify(this.searchQuery)
      );
    },
    getSearchFilter() {
      // prende parametri di ricerca
      return JSON.parse(localStorage.getItem("opendata_searchQuery"));
    },
    getParameterFilter() {
      // prende parametri di tabella
      return JSON.parse(localStorage.getItem("opendata_serverParams"));
    },
    onPerPageChange(params) {
      // risultati per pagina
      console.log("onPerPageChange");
      console.log(params);
      this.serverParams.pagination = params;
      this.updateResource();
    },
    onPageChange(params) {
      // risultati nel numero di pagina della paginazione (Es: pag 2 di 5)
      console.log("onPageChange");
      console.log(params);
      this.serverParams.pagination = params;
      this.updateResource();
    },
    onSortChange(params) {
      // ordinamento risultati per campo
      console.log("onSortChange");
      console.log(params[0]);
      this.serverParams.sort = params[0];
      this.updateResource();
    },

    async updateResource() {
      const self = this;
      self.rows = [];
      self.isLoading = true;

      var typeSort = "+";
      if (this.serverParams.sort.type == "desc") typeSort = "-";

      this.saveParameterFilter();

      await axios({
        method: "GET",
        url: this.$url + "operations/makexp",
        headers: this.$config.axiosHeaders(),
        params: {
          keywords: self.searchQuery.searchTerm,
          provincia: self.searchQuery.provincia,
          comune: self.searchQuery.comune ? self.searchQuery.comune.city : "",
          orderBy: typeSort + self.serverParams.sort.field,
          page: self.serverParams.pagination.currentPage,
          limit: self.serverParams.pagination.currentPerPage,
        },
      })
        .then(function (response) {
          self.elements = [...response.data.data.items];
          self.totalRecords = response.data.data.items_count;

          return;
          self.rows = [];
          var obj = response.data.data.items;
          for( let entity of obj){

            self.rows.push({
              id: entity.id,
              title: entity.title,
              city: "", //entity.locations.,
              link: "",
              created_at: entity.dates.start_date + "/" + entity.dates.end_date,
              contents: entity.description,
            });
          }
        })
        .catch(function (error) {
          self.$processError(error);

        })
        .finally(() => (self.isLoading = false));
    },
  },
};
</script>
