<template>
    <div>
        <div class="row">
            <div class="col-12 mt-2 mb-2" >
                <div class="text-right" role="toolbar" aria-label="Bottoni azione">
                     <input
                        type="button"
                        class="btn mb-2 btn-success active"
                        value="Aggiungi collezione POI"
                        @click="show_dialog_category(-1,'')"

                />
                </div>
            </div>
        </div>

        <div class="card">
            <vue-good-table
                    ref="generic-table"
                    mode="remote"
                    styleClass="vgt-table striped"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    title="Pagine"
                    :columns="columns"
                    :rows="rows"
                    totalRecords
                    :paginate="true"
                    :lineNumbers="false"
                    :pagination-options="{
                                enabled: true,
                                position: 'top',
                                nextLabel: 'Successivo',
                                prevLabel: 'Precedente',
                                rowsPerPageLabel: 'Righe per pagina',
                                dropdownAllowAll: false,
                                perPage: serverParams.pagination.currentPerPage,
                                perPageDropdown: serverParams.myPerPageDropdown,
                                setCurrentPage: serverParams.pagination.currentPage
                    }"
                    :sort-options="{ enabled: true, }"
                    :selectOptions="{
                          enabled: false,
                          selectOnCheckboxOnly: true,
                          selectionInfoClass: 'generic-class',
                          selectionText: 'righe selezionate',
                          clearSelectionText: 'deseleziona',
                    }"
                    :isLoading="isLoading"
            >
                <div slot="emptystate" id="emptystate" v-if="totalRecords == 0">
                    <div class="vgt-center-align vgt-text-disabled">
                        <span v-if="!isLoading">Nessun risultato disponibile</span>
                    </div>
                </div>

                <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <div class="btn-group btn-group-sm mr-2" role="group" aria-label="Azioni">
                   <button title="Modifica" type="button"
                        class="btn btn-primary btn-sm" @click="show_dialog_category(props.row.id,props.row.title)">
                        <span class="fa fa-pencil"></span>
                    </button>
                   <router-link title="Visualizza" tag="button" type="button" class="btn btn-default btn-sm"
                         :to="{ name: 'Elenco POI', params: {code:props.row.code}}" >
                        <span class="fa fa-list"></span>
                    </router-link>
                  </div>
                </span>


                    <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
                </template>
                <!-- @deprecated: disabilitato dallo sviluppatore
                <div slot="selected-row-actions">
                    <a v-on:click="deleteAll()" v-if="entity_delete">
                        <p-button type="primary">Cancella Selezionati</p-button>
                    </a>
                </div>
                -->

            </vue-good-table><!-- chiusura componente -->
        </div>



    <modal name="Gestione Luoghi" width="60%" height="auto"
                :scrollable="true"
                :resizable="true"
                :adaptive="true"
        >
            <div class="modal-content" style="position:sticky;">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalcategorie">Gestione Luoghi</h5>
                    <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="$modal.hide('Gestione Luoghi')"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form>
                    <div class="modal-body">

                       <div class="form-group">
                            <textbox
                                    title="Nome"
                                    v-model="currentTitle"
                            />
                        </div><!-- titolo -->



                    </div>
                    <div class="modal-footer">
                        <input
                                type="button"
                                class="btn btn-secondary"
                                value="CHIUDI"
                                @click="$modal.hide('Gestione Luoghi')"
                        />
                        <button class="btn btn-primary" :disabled="disabled_submit" @click="sendRequest()">INVIA</button><img v-if="disabled_submit" src="@/assets/img/spinner.gif"
                                               alt="loading" width="16" height="16" class="ml-2">
                    </div>
                </form>
            </div>
    </modal>







    </div>
</template>

<script>
    import axios from 'axios';

    import SearchBox from "@/components/altrama/SearchBox";
    import StatusesBox from "@/components/altrama/StatusesBox";
    import StatusesSpan from "@/components/altrama/StatusesSpan";
    import ProvinceCombo from "@/components/altrama/ProvinceCombo";
    import DestinationBox from "@/components/altrama/DestinationBox";
    import CityCombo from "@/components/altrama/CityCombo";
    import Textbox from "@/components/altrama/form/Textbox";

    const tableColumns = ["Id", "Cognome", "Email"]; //mantenere per i campi tolowercase header tabella
    var tableData = [];
    var self = this;

    export default {
        components: {

            SearchBox,
            StatusesBox,
            StatusesSpan,
            ProvinceCombo,
            DestinationBox,
            CityCombo,
            Textbox

        },

        data() {
            return {
                table1: {
                    title: "Elenco",
                    columns: [...tableColumns],
                    data: [...tableData],
                    route: "tipologia-luoghi",

                },
                columns: [
                    {
                        label: 'ID',
                        field: 'id',
                        type: 'number',
                        html: false,

                    },

                    {
                        label: 'TITOLO',
                        field: 'title',
                        filterable: false,
                        sortable: false,
                    },
                     {
                        label: 'CODICE',
                        field: 'code',
                        filterable: true,
                        sortable: false,

                    },


                    {
                        label: 'AZIONI',
                        field: 'action',
                        filterable: false,
                        html: true,
                        sortable: false,
                    }
                ],
                entity_create: false,
                entity_delete: false,
                rows: [],
                totalRecords: 0,
                serverParams: {
                    columnFilters: {},
                    sort: {
                        field: "id",
                        type: "desc"
                    },
                    pagination: {
                        currentPage: 1,
                        currentPerPage: 50
                    },
                    myPerPageDropdown: [10, 20, 30, 40, 50]
                },
                isLoading: false,
                searchQuery: {
                    'searchTerm': '',
                    'provincia': -1,
                    'comune': {'id': null},
                    'destinazione': {'id': null},
                },

                langs:this.$available_langs_code,
                code: 0,
                //variabili per dialog
                disabled_submit:false,
                currentId:-1,
                currentTitle:'',
            };
        },
         created: async function () {


            if (await this.$api.user.can(this.table1.route + "-view") == false) {
                this.$router.replace("/not-found");
                return;
            }
            this.entity_create = await this.$api.user.can(this.table1.route + "-create");
            this.entity_delete = await this.$api.user.can(this.table1.route + "-delete");
        },
        mounted: function () {
            if (localStorage.getItem('page_searchQuery')) { // parametri di ricerca
                this.searchQuery = this.getSearchFilter();
            }
            if (localStorage.getItem('page_serverParams')) { // parametri di tabella
                this.serverParams = this.getParameterFilter();
            }
            this.updateResource();
        },

         watch: {
            code: {
                handler: function () {
                    this.noFilter();
                    location.reload();
                },
                deep: true
            }
        },

        methods: {
            filter() {
                this.serverParams.pagination.currentPage = 1;
                this.updateResource();
            },
            noFilter() {
                localStorage.removeItem("page_searchQuery");
                localStorage.removeItem("page_serverParams");

                this.serverParams = {
                    columnFilters: {},
                    sort: {
                        field: "id",
                        type: "desc"
                    },
                    pagination: {
                        currentPage: 1,
                        currentPerPage: 50
                    },
                    myPerPageDropdown: [10, 20, 30, 40, 50]
                };
                this.searchQuery = {};
                this.updateResource();
            },
            saveParameterFilter() {
                localStorage.setItem(
                    "page_searchQuery",
                    JSON.stringify(this.searchQuery)
                );
                localStorage.setItem(
                    "page_serverParams",
                    JSON.stringify(this.serverParams)
                );
            },
            saveSearchFilter() { // salva parametri di ricerca
                localStorage.setItem('page_searchQuery', JSON.stringify(this.searchQuery));
            },
            getSearchFilter() { // prende parametri di ricerca
                return JSON.parse(localStorage.getItem('page_searchQuery'));
            },
            getParameterFilter() { // prende parametri di tabella
                return JSON.parse(localStorage.getItem('page_serverParams'));
            },
            onPerPageChange(params) { // risultati per pagina
                this.serverParams.pagination = params;
                this.updateResource();
            },
            onPageChange(params) { // risultati nel numero di pagina della paginazione (Es: pag 2 di 5)
                this.serverParams.pagination = params;
                this.updateResource();
            },
            onSortChange(params) { // ordinamento risultati per campo
                this.serverParams.sort = params[0];
                this.updateResource();
            },

            async updateResource() {
                var self = this;
                self.rows = [];
                self.isLoading = true;

                var typeSort = "+";
                if (this.serverParams.sort.type == "desc") {
                    typeSort = "-";
                }

                this.saveParameterFilter();

                await axios({
                    method: 'GET',
                    url: self.$url + this.table1.route,
                    headers: this.$config.axiosHeaders(),
                    params: {
                        keywords: self.searchQuery.searchTerm,
                        destinazione: self.searchQuery.destinazione ? self.searchQuery.destinazione.id : -1,
                        provincia: self.searchQuery.provincia,
                        comune: self.searchQuery.comune ? self.searchQuery.comune.id : -1,
                        orderBy: typeSort + self.serverParams.sort.field,
                        page: self.serverParams.pagination.currentPage,
                        limit: self.serverParams.pagination.currentPerPage
                    }
                }).then(function (response) {

                    self.totalRecords = response.data.total;
                    self.rows = [];
                    var obj = response.data.data;

                for( let entity of obj){
                        self.rows.push({
                            id: entity.id,
                            title: entity.title,
                            code: entity.code,


                        });
                    }
                }).catch(function (error) {
                    self.$processError(error);

                }).finally(() => (self.isLoading = false));
            },
            deleteAll: function () {
                if (confirm('Confermare cancellazione degli elementi selezionati?')) {
                    var elenco = [];
                    var selected = this.$refs['generic-table'].selectedRows;
                    for (var i = 0; i < selected.length; i++) {
                        elenco.push(selected[i].id);
                    }
                    var self = this;
                    self.isLoading = true;

                    axios({
                        method: 'DELETE',
                        url: this.$url + this.table1.route + '/' + elenco,
                        headers: this.$config.axiosHeaders(),
                    }).then(function (response) {
                        self.$notifyVue('top', 'center', 'success', response.data.data.message, 'ti-check');
                        self.updateResource();
                    }).catch(function (error) {
                        self.$processError(error);

                    }).finally(
                        () => self.isLoading = false
                    );
                }
            },
             makeTitle(slug) {
                    var words = slug.split('_');
                    for (var i = 0; i < words.length; i++) {
                        var word = words[i];
                        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
                    }
                    return words.join(' ');
            },

            //gestione dialog
             show_dialog_category(id,title) {
                this.currentId=id;
                this.currentTitle=title;
                this.$modal.show("Gestione Luoghi");
            },
             sendRequest() {
                 let self = this;
                 let method = 'post';
                 let  url = `${self.$url}set-luogo/${self.currentId}/${self.currentTitle}`;
                 self.disabled_submit=true;
                    axios({
                        method: 'post',
                        url: url,
                        headers: this.$config.axiosHeaders(),
                    })
                        .then(function (response) {
                            self.loading = false;
                            self.$notifyVue(
                                "top",
                                "center",
                                response.data.status,
                                response.data.data.message,
                                "ti-check"
                            );
                            self.$modal.hide('Gestione Luoghi')
                            self.updateResource();
                            self.disabled_submit=false;

                        })
                        .catch(function (error) {
                            self.$processError(error);

                            self.disabled_submit=false;
                        });
            },
        } // methods
    };
</script>
